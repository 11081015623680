import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, Button, Grid, CircularProgress, Box, Divider, Snackbar, Alert,
  Card, CardContent, TextField, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import { ArrowBack, QuestionAnswer, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

function EntryDetail() {
  const { uid } = useParams();
  const [entry, setEntry] = useState(null);
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [foundUrl, setUrlfound] = useState(null);
  const [relevantData, setRelevantdata] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [qaList, setQaList] = useState([]);
  const [openModal, setOpenModal] = useState(false);


  useEffect(() => {
    const fetchEntryDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/entry/${uid}`);
        setEntry(response.data);
        setUrlfound(true);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setUrlfound(false);
        } else {
          console.error('Error fetching entry detail:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEntryDetail();
  }, [uid]);

  const handleSummarize = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/summarize/${uid}`);
      setSummary(response.data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAskClick = async () => {
    setQuestionLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/api/query`, {
        question: question,
        kval: "1",
        uid: uid,
        get_docs_only: "false"
      });
      setQaList(prevQaList => [...prevQaList, { question: question, answer: response.data }]);
      setQuestion('');
    } catch (error) {
      console.error('Error posting question:', error);
    } finally {
      setQuestionLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleOpenReport = () => {
    if (entry && entry.report_url) {
      window.open(entry.report_url, '_blank');
    }
  };

  const handleOpenAIExtract = async () => {
    setLoadingAI(true);
    try {
      const response = await axios.get(`${baseUrl}/api/entry/${uid}/relevantcontent`);
      setRelevantdata(response.data);
      setOpenModal(true);  // Open the modal when data is received
      const originalLength = entry.text.length;
      const extractedLength = response.data.length;
      const reductionPercentage = ((originalLength - extractedLength) / originalLength * 100).toFixed(2);
      setSnackbarMessage(`Displaying only relevant content (${reductionPercentage}% shorter)`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error fetching AI-Extract:', error);
    } finally {
      setLoadingAI(false);
    }
  };

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleAskClick();
  };


  if (foundUrl === false) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Entry not found.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleBack}>
          Go Home
        </Button>
      </Container>
    );
  }

  if (!entry) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="2rem">
        <Button variant="contained" startIcon={<ArrowBack />} onClick={handleBack}>
          Back
        </Button>
        <Typography variant="h6" component="h1" gutterBottom>
          {entry.company_name}
        </Typography>
        <Button variant="outlined" startIcon={<QuestionAnswer />} disabled={questionLoading || question.trim().length === 0} onClick={handleAskClick}>
          {questionLoading ? <CircularProgress size={24} color="inherit" /> : 'Ask'}
        </Button>
      </Box>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="ai-extract-dialog-title"
        aria-describedby="ai-extract-dialog-description"
      >
        <DialogTitle id="ai-extract-dialog-title">AI Extracted Content</DialogTitle>
        <DialogContent>
          <DialogContentText id="ai-extract-dialog-description">
            {relevantData}
          </DialogContentText>
        </DialogContent>
      </Dialog>



      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant="subtitle2" color="textSecondary">
                Posted Date: {new Date(entry.posted_date).toLocaleDateString()}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Letter Issue Date: {new Date(entry.letter_issue_date).toLocaleDateString()}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Issuing office: {entry.issuing_office}
              </Typography>
              <Box component="span" m={1}>
                <Button variant="outlined" onClick={handleSummarize} style={{ marginTop: "16px" }} disabled={loading} size="small" color="primary">
                  {loading ? <CircularProgress size={24} /> : 'Summarize Letter'}
                </Button>
              </Box>
              <Box component="span" m={1}>
                <Button variant="outlined" onClick={handleOpenReport} style={{ marginTop: "16px" }} size="small" color="primary">
                  Open FDA Page
                </Button>
              </Box>
              <Divider style={{ margin: '1rem 0' }} />
              <Accordion expanded={expanded} onChange={toggleAccordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>View Original Letter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component="p">
                    {relevantData || entry.text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={6}>
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              label="Ask about letter"
              placeholder='e.g. What is this letter about?'
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              margin="normal"
              inputProps={{ maxLength: 200 }}
            />
          </form>

          {qaList.map((qa, index) => (
            <Card variant="outlined" key={index} style={{ marginTop: '20px' }}>
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  Question: {qa.question}
                </Typography>
                <Typography variant="body2" component="div">
                  Answer: {qa.answer}
                </Typography>
              </CardContent>
            </Card>
          ))}

          {summary && (
            <Card variant="outlined" style={{ marginTop: '20px' }}>
              <CardContent>
                <Typography variant="h6" component="div" style={{ marginBottom: '8px' }}>
                  Summary of the Letter
                </Typography>
                <Typography variant="body2" component="div">
                  {summary}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>




      </Grid>
      <Snackbar style={{ border: '1px solid #2c93d5' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EntryDetail;
