// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Entries from './Entries';
import EntryDetail from './EntryDetail';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Entries />} />
        <Route path="/entry/:uid" element={<EntryDetail />} />
        <Route path="/entrydetail/:uid" element={<EntryDetail />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
