import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, TextField, MenuItem, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material'; // Added Grid here
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function Entries() {
  const [entries, setEntries] = useState([]);
  const [skip, setSkip] = useState(0);
  const limit = 50;
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('All');

  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    document.title = "FDA Warning Letters";
    fetchEntries();
  }, []);

  const fetchEntries = async (more = false) => {
    try {
      const response = await axios.get(`${baseUrl}/api/entries?skip=${skip}&limit=${limit}`);
      setEntries(more ? [...entries, ...response.data] : response.data);
      setSkip(skip + limit);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const postData = {
        question: query,
        kval: "10",
      };
      if (filter !== 'All') {
        postData.product_group = [filter];
      }
      const response = await axios.post(`${baseUrl}/api/query`, postData);
      setEntries(response.data);
      setSkip(0);
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const filters = [
    "All",
    "Medical Devices",
    "Animal & Veterinary",
    "Drugs",
    "Food & Beverages",
    "Tobacco",
    "Biologics",
    "Dietary Supplements"
  ];

  return (
    <Container maxWidth="md" style={{ marginTop: '80px', marginBottom: "60px" }}>
      <h1 style={{ textAlign: 'center', marginBottom: "2rem" }}>FDA Warning Letters Database</h1>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={8}>
          <TextField
            label="Search"
            variant="outlined"
            placeholder='e.g. Vape products'
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Filter</InputLabel>
            <Select
              value={filter}
              label="Filter"
              onChange={(e) => setFilter(e.target.value)}
            >
              {filters.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" disabled={!query.trim()} onClick={handleSearch} fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Posted Date</TableCell>
              <TableCell>Product Group</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry, index) => (
              <TableRow key={index}>
                <TableCell>{entry.company_name}</TableCell>
                <TableCell>{new Date(entry.posted_date).toLocaleDateString()}</TableCell>
                <TableCell>{entry.product_group}</TableCell>
                <TableCell>
                  <Button component={RouterLink} to={`/entry/${entry.uid}`} endIcon={<KeyboardArrowRightIcon />} variant="outlined">View</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="outlined" color="primary" onClick={() => fetchEntries(true)} style={{ marginTop: '20px' }}>
        Show More
      </Button>
    </Container>
  );
}

export default Entries;
